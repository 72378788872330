<template>
    <div>
        <!-- search -->
        <div class="container-fluid bg-lightblue py-4">
            <div class="row">
                <div class="col-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <span class="mp-mts text-center" style="font-size: 1rem; display: block;">
                                    Teste jetzt mit Gutschein-Code die Pakete Basic oder Professional für 6 Monate kostenlos!<br>
                                    Code: PRESENT (gültig bis 31.01.2025, keine Barablöse möglich)<br>
                                </span>
                                <span class="mp-mts text-center mt-2" style="font-size: 0.9rem; display: block;">
                                    Fragen und Anregungen bzw. Hinzufügen von Fachbereichen gerne an <a class="registration-expert-links" href="mailto:office@mergeplate.com">office@mergeplate.com</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid bg-slider px-0">
            <div class="container-fluid overlay-black">
                <div class="row">
                    <div class="col-12">
                        <div class="container py-5">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center mt-0 mt-lg-5 pt-2 pt-lg-3">
                                    <h1 class="d-lg-block d-none text-white mp-rbk mp-font-weight-medium text-center font-size-search-title">
                                        mergeplate.com
                                    </h1>
                                    <h1 class="d-lg-none d-block text-white mp-rbk mp-font-weight-medium text-center font-size-search-title-mobile">
                                        mergeplate.com
                                    </h1>
                                </div>
                            </div>
                            <div class="row py-3">
                                <div class="col-12 col-lg-8 offset-lg-2 d-flex justify-content-center">
                                    <p class="d-lg-block d-none text-white mp-mts font-size-search-description text-center">Deine Plattform für mentale und physische Gesundheit</p>
                                    <p class="d-lg-none d-block text-white mp-mts font-size-search-description-mobile text-center">Deine Plattform für mentale und physische Gesundheit</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-10 offset-lg-1 d-flex justify-content-center mt-5">
                                    <div class="input-group">
                                        <span class="border-0 input-group-text search form-rounded-left" id="basic-addon2"><i class="fa fa-search"></i></span>
                                        <input
                                            class="form-control py-3 border-0"
                                            list="searchDataListOptions"
                                            id="searchDataList"
                                            placeholder="Suche z.B. &quot;Selbsterfahrung&quot;"
                                            v-model="searchText"
                                            @change="doSearch(searchText)"
                                        >
                                        <datalist id="searchDataListOptions">
                                            <option v-for="t in searchOptions" :value="t.title" :key="t.id">{{ t.title }}</option>
                                        </datalist>

                                        <button
                                            class="btn btn-outline-secondary form-rounded-right btn-search-green mp-qks mp-font-weight-medium font-size-search-button border-0"
                                            type="button" id="button-addon2"
                                            @click="doSearch(searchText)"
                                        >Suchen
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-lg-block d-none">
                                <div
                                    class="col-12 col-lg-10 offset-lg-1 pb-3 d-flex flex-wrap align-items-center justify-content-center py-4">
                                    <div class="pe-2 py-2">
                                        <span
                                            class="mp-font-weight-regular mp-qks font-size-frequently-searched text-white">Oft gesucht:
                                        </span>
                                    </div>

                                    <ul class="nav col-12 col-lg-auto justify-content-center justify-content-lg-end">
                                        <li class="px-2 py-2" v-for="t in searchedOften" :key="`${keyPrefix}_${t.id}_so`">
                                            <button class="btn btn-frequently-searched text-white form-rounded-left form-rounded-right py-2 font-size-frequently-searched-btn" type="button"
                                                    @click="doSearch(t.title)"
                                            >{{ t.title }}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- discover -->
        <div class="container-fluid bg-lightblue py-2 py-lg-5">
            <div class="row">
                <div class="col-12">
                    <div class="container py-4">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center mb-4">
                                <span class="d-lg-block d-none section-title mp-rbk text-center">Finde deinen Experten</span>
                                <span class="d-lg-none d-block section-title-mobile mp-rbk text-center" style="hyphens: none">Finde deinen Experten</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4 col-lg-4 py-2 px-2 d-flex justify-content-center"
                                 v-for="c in categoriesFiltered" :key="`${keyPrefix}_${c.id}_c`"
                            >
                                <router-link :to="`/fachgebiete/${c.title}/${c.id}`">
                                    <div class="discover-image position-relative d-flex align-items-center justify-content-center" :data-content="c.title.toUpperCase()">
                                        <img class="mw-100 form-rounded-left form-rounded-right" :src="c.media.url" />
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- description -->
        <div class="container-fluid py-2 py-lg-5">
            <div class="row">
                <div class="col-12">
                    <div class="container py-4">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center mb-4">
                                <span class="d-lg-block d-none font-size-description-title mp-font-weight-medium mp-rbk text-center">Deine Vorteile</span>
                                <span class="d-lg-none d-block font-size-description-title-mobile mp-font-weight-medium mp-rbk text-center">Deine Vorteile</span>
                            </div>
                        </div>
                        <div class="row" style="min-height: 290px; hyphens: none;">
                            <div class="col-12 col-lg-4 mb-4">
                                <div class="card py-2 py-lg-5 px-2 form-rounded-left form-rounded-right form-shadow h-100">
                                    <div class="card-body">
                                        <h5 class="card-title mp-rbk font-size-description-card-title text-center">
                                            finde deinen Experten kostenlos
                                        </h5>
                                        <p class="card-text mp-qks mp-font-weight-medium font-size-description-card-text text-center">
                                            Durch unser Angebot für physische und mentale Gesundheit findest du kostenlos deinen passenden Experten.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-4">
                                <div class="card py-2 py-lg-5 px-2 form-rounded-left form-rounded-right form-shadow h-100">
                                    <div class="card-body">
                                        <h5 class="card-title mp-rbk font-size-description-card-title text-center">
                                            bleibe anonym ohne Registrierung
                                        </h5>
                                        <p class="card-text mp-qks mp-font-weight-medium font-size-description-card-text text-center">
                                            Die direkten Kontaktmöglichkeiten mit deinem Experten garantieren dir eine anonyme Nutzung der Plattform.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-4">
                                <div class="card py-2 py-lg-5 px-2 form-rounded-left form-rounded-right form-shadow h-100">
                                    <div class="card-body">
                                        <h5 class="card-title mp-rbk font-size-description-card-title text-center">
                                            profitiere von geprüften Experten
                                        </h5>
                                        <p class="card-text mp-qks mp-font-weight-medium font-size-description-card-text text-center">
                                            Der Bewerbungsprozess garantiert dir ausschließlich verifizierte Experten-Profile auf mergeplate.com.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- expert listing -->
        <div class="container-fluid bg-lightblue py-4 py-lg-5">
            <div class="row">
                <div class="col-12 d-flex justify-content-center mb-4">
                    <span class="d-lg-block d-none section-title mp-rbk text-center">Experten</span>
                    <span class="d-lg-none d-block section-title-mobile mp-rbk text-center">Experten</span>
                </div>
            </div>
            <div class="row">
                <div class="col-10 offset-1 d-flex justify-content-center mb-4 d-lg-block d-none">
                    <p class="text-center mp-qks font-size-expert-listing-sub mp-font-weight-medium">
                        Scrolle durch die Sammlung oder nutze die Filteroptionen, um gezielt zu finden wonach du suchst.
                    </p>
                </div>
            </div>
            <OfferFilter :show-experts="true" :result-limit="4" :show-filter=true :search-enabled="false" />
        </div>

        <!-- tag cloud -->
        <TagCloud class="d-none d-md-block"/>

        <!-- modals -->
        <EmailChange v-if="showEMailChangeDlg" :token="emailPin" @close="showEMailChangeDlg = false" />
        <PasswordChange v-if="showPasswordChangeDlg" :token="passwordPin" @close="showPasswordChangeDlg = false" />
        <EMailVerify v-if="showEMailVerifyDlg" :token="regPin" @close="showEMailVerifyDlg = false" />
    </div>
</template>



<script>
    import {mixinBasics} from "../mixins/basics";
    import TagCloud from "../components/TagCloud";
    import eventBus from "../eventbus";
    import Const from "../constants";
    import EmailChange from "../components/modals/EmailChange";
    import PasswordChange from "../components/modals/PasswordChange";
    import EMailVerify from "../components/modals/EMailVerify";
    import OfferFilter from "../components/OfferFilter.vue";


    export default {
        name: "Index",

        metaInfo() {
            return {
                meta: [
                    { name: 'title', content: 'Home | mergeplate.com'},
                    { name: 'description', content: 'Home | mergeplate.com'},
                ]
            };
        },

        components: {
            OfferFilter,
            EMailVerify,
            PasswordChange,
            EmailChange,
            TagCloud
        },

        mixins: [
            mixinBasics
        ],

        props: {
            emailPin: {
                type: String,
                required: false,
                default: undefined
            },
            passwordPin: {
                type: String,
                required: false,
                default: undefined
            },
            regPin: {
                type: String,
                required: false,
                default: undefined
            },
        },

        data() {
            return {
                keyPrefix: 'idx',
                searchText: undefined,

                showEMailChangeDlg: false,
                showPasswordChangeDlg: false,
                showRegisterVerificationDlg: false,
                showEMailVerifyDlg: false
            };
        },

        computed: {
            searchedOften() {
                let start = this.getRandomInt(0, this._tagsInUse.length - 4);
                return this._tagsInUse.slice(start, start+4);
            },

            categoriesFiltered() {
                return this._categories.filter(c => c.areas.length > 0);
            },

            searchOptions() {
                if (!this.searchText) {
                    return this._tagsInUse;
                }
                let search = this.searchText.toLowerCase();
                return this._tagsInUse.filter(t => t.title.toLowerCase().indexOf(search) >= 0);
            }
        },

        methods: {
            showExpertsByCategory(categoryId) {
                this.$router.push({path: `/expert/${categoryId}`, replace: true});
            },

            doSearch(e) {
                eventBus.$emit(Const.events.DO_SEARCH, e);
            },
        },

        mounted() {
            if (this.emailPin) {
                console.log('---> EMAIL-Change: ' + this.emailPin)
                this.showEMailChangeDlg = true;
            }
            if (this.passwordPin) {
                console.log('---> PASSWORD-Change: ' + this.passwordPin)
                this.showPasswordChangeDlg = true;
            }
            if (this.regPin) {
                console.log('---> REG-Verify: ' + this.regPin)
                this.showEMailVerifyDlg = true;
            }
            window.scrollTo(0,0);
        }
    }

</script>


<style scoped>
    .map {
        width: 100%;
        height: 300px;
        display: inline-block;
        max-height: 100%;
        overflow: auto;
        border: 2px ridge silver;
        background-color: rgb(229, 227, 223);
    }
</style>
